import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {
    TextField,
    Button,
    MenuItem,
  } from '@mui/material';
  import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { tokens } from "../theme";

import axios from 'axios';
import { global_link } from '../Components/Globallink';
import TokenExpiration from '../Auth/TokenExpiration';
import Expiration from '../Components/Expiration';
import { saveAs } from 'file-saver'; // For downloading files
import { PDFDownloadLink, Document, Page, Text } from '@react-pdf/renderer';


// SUBMIT NOTIFICATIONS
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
// SUBMIT NOTIFICATIONS


const api = axios.create({baseURL: global_link});


function Reports() {


const theme = useTheme();
const colors = tokens(theme.palette.mode);
const [selectedTab, setSelectedTab] = useState(0);
const [formData, setFormData] = useState({});
const [reportdata, setReportData] = useState([]);
const [IndividualValue, setIndividualValue] = useState([]);
const [UserID, setUserID] = useState('');
const [TicketCatgoryData, setTicketCatgoryData] = useState([]);
const [DispositionCatgoryData, setDispositionCatgoryData] = useState([]);
const [BreakTypeData, setBreakTypeData] = useState([]);
const [CallLogsData, setCallLogsData] = useState([]);



// SUBMIT NOTIFICATIONS

const [openSuccess, setOpenSuccess] = useState(false);
const [openError, setOpenError] = useState(false);

const handleSuccessClose = (event, reason) => {
  if (reason === 'clickaway') {return;}setOpenSuccess(false);}; 

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {return;}setOpenError(false);}; 


  const [state, setState] = useState({openServer: false,vertical: 'top',horizontal: 'center',});
  const { vertical, horizontal} = state;

  const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
    

  useEffect(() => {
    setOpenSuccess(false);
    setOpenError(false);
  },[])



const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
 const [username, setUsername] = useState('');


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };


  const handlePerformanceReportSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const accessToken = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };
    try {
      const response = await api.get('/performancereports/', {
        params: {
          keyword: formData.get('keyword'),
          start_date: formData.get('start_date'),
          end_date: formData.get('end_date'),
        },     
          headers,
          withCredentials: true,
      });
      // if(response.data.length>0){
      //   setOpenSuccess(false);
      //   let headings;
      //   if(formData.get('keyword')!="Received"){
      //     headings = ['(Extension) User','All Calls','Outgoing','Incoming','Received','Missed','Not Found',' Total Talk Time'];
      //   }else{
      //     headings = ['(Extension) User','All Calls','Outgoing','Incoming','Received','Missed','Not Found',' Total Talk Time'];
      //   }
      //   const csvContent = [headings.join(',')].concat(response.data.map(item => [`(${item.user_extension}) ${item.user_name}`,item.call_count,item.total_outgoing,item.total_incoming,item.received,item.missed,item.not_found,item.total_duration].join(','))
      //   ).join('\n');
      //   const csvBlob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      //   saveAs(csvBlob,`${formData.get('keyword')}-${formData.get('start_date')}_${formData.get('end_date')}-Performance_Report.csv`);
      // }else{
      //   setOpenError(true);
      // }

      if (response.data.length > 0) {
        setOpenSuccess(false);
        let headings;
        if (formData.get('keyword') !== 'Received') {
          headings = ['(Extension) User', 'All Calls', 'Outgoing', 'Incoming', 'Received', 'Missed', 'Not Found', 'Total Talk Time'];
        } else {
          headings = ['(Extension) User', 'All Calls', 'Outgoing', 'Incoming', 'Received', 'Missed', 'Not Found', 'Total Talk Time'];
        }
      
        let totalCallCount = 0;
        let totalOutgoing = 0;
        let totalIncoming = 0;
        let totalReceived = 0;
        let totalMissed = 0;
        let totalNotFound = 0;
        let totalDuration = 0;
        let totalDurationMilliseconds = 0;
      
        response.data.forEach(item => {
          totalCallCount += item.call_count;
          totalOutgoing += item.total_outgoing;
          totalIncoming += item.total_incoming;
          totalReceived += item.received;
          totalMissed += item.missed;
          totalNotFound += item.not_found;
          const durationParts = item.total_duration.split(':').map(Number);
          const durationMilliseconds = (durationParts[0] * 3600 + durationParts[1] * 60 + durationParts[2]) * 1000;
          totalDurationMilliseconds += durationMilliseconds;
        });

        const totalDurationDate = new Date(totalDurationMilliseconds);
        const hours = totalDurationDate.getUTCHours();
        const minutes = totalDurationDate.getUTCMinutes();
        const seconds = totalDurationDate.getUTCSeconds();
        const formattedTotalDuration = `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

        const footerRow = ['Total', `${totalCallCount}`, `${totalOutgoing}`, `${totalIncoming}`, `${totalReceived}`, `${totalMissed}`, `${totalNotFound}`, `${formattedTotalDuration}`];
        const csvContent = [headings.join(',')].concat(
          response.data.map(item => [`(${item.user_extension}) ${item.user_name}`, item.call_count, item.total_outgoing, item.total_incoming, item.received, item.missed, item.not_found, item.total_duration].join(','))
        ).concat([footerRow.join(',')]).join('\n');
        const csvBlob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob, `${formData.get('keyword')}-${formData.get('start_date')}_${formData.get('end_date')}-Performance_Report.csv`);
      } else {
        setOpenError(true);
      }
      
    } catch (error) {
      console.error('Error fetching data:', error);  
    setOpenError(true);
    }
  }

 
  const handleCallReportSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const accessToken = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };
    try {
      const response = await api.get('/callreports/', {
        params: {
          keyword: formData.get('keyword'),
          start_date: formData.get('start_date'),
          end_date: formData.get('end_date'),
        },     
          headers,
          withCredentials: true,
      });
      if(response.data.length>0){
        setOpenSuccess(false);
        let headings;
        if(formData.get('keyword')!=="Received"){
          headings = ['Name','Source','Destination','Call Status','Call Type','Time/Date'];
        }else{
          headings = ['Name','Source','Destination','Duration','Call Type','Time/Date'];
        }
        const csvContent = [headings.join(',')].concat(response.data.map(item => [item.user_name,item.call_from,item.call_to,item.call_duration,item.call_type,item.call_time.slice(0, -16).replace('T', ' ')].join(','))
        ).join('\n');
        const csvBlob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob,`${formData.get('keyword')}-${formData.get('start_date')}-${formData.get('end_date')}-${formData.get('report')}_Report.csv`);
      }else{
        setOpenError(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);  
    setOpenError(true);
    }
  };

  
 
  const handleCallIndividualReportSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const accessToken = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };
    try {
      const response = await api.get('/individualreports/', {
        params: {
          user_id: formData.get('user_id'),
          keyword: formData.get('keyword'),
          start_date: formData.get('start_date'),
          end_date: formData.get('end_date'),
        },     
          headers,
          withCredentials: true,
      });
      if(response.data.length>0){
        setOpenSuccess(false);
        let headings;
        if(formData.get('keyword')!=="Received"){
          headings = ['Name','Source','Destination','Call Status','Call Type','Time/Date'];
        }else{
          headings = ['Name','Source','Destination','Duration','Call Type','Time/Date'];
        }
        const csvContent = [headings.join(',')].concat(response.data.map(item => [item.user_name,item.call_from,item.call_to,item.call_duration,item.call_type,item.call_time.slice(0, -16).replace('T', ' ')].join(','))
        ).join('\n');
        const csvBlob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob,`${formData.get('keyword')}-${formData.get('start_date')}-${formData.get('end_date')}-${formData.get('report')}_Report.csv`);
      }else{
        setOpenError(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);  
    setOpenError(true);
    }
  };

  const handleTicketReportSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const accessToken = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };
    try {
      const response = await api.get('/ticketreports/', {
        params: {
          keyword: formData.get('keyword'),
          start_date: formData.get('start_date'),
          end_date: formData.get('end_date'),
        },     
          headers,
          withCredentials: true,
      });
      if(response.data.length>0){
        setOpenSuccess(false);
        const headings = ['Ticket Id','Name','Subject','Description','Status','Priority','Date','Category','Email','Phone Number','Alt Phone Number','Channel'];
        const csvContent = [headings.join(',')].concat(response.data.map(item => [item.id,item.user_name,item.subject,item.description,item.status,item.priority,item.created_at.slice(0, -16).replace('T', ' '),item.category,item.email,item.phone_number,item.alternative_phone_number,item.channel].join(','))
        ).join('\n');
        const csvBlob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob,`${formData.get('keyword')}-${formData.get('start_date')}-${formData.get('end_date')}-Ticket_Report.csv`);
      }else{
        setOpenError(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);  
    setOpenError(true);
    }
  }


  const handleDispositionReportSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const accessToken = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };
    try {
      const response = await api.get('/dispositionreports/', {
        params: {
          keyword: formData.get('keyword'),
          start_date: formData.get('start_date'),
          end_date: formData.get('end_date'),
        },     
          headers,
          withCredentials: true,
      });
      if(response.data.length>0){
        setOpenSuccess(false);
        const headings = ['Id','User','Contact','Call Type','Disposition','Disposition Info','Date'];
        const csvContent = [headings.join(',')].concat(response.data.map(item => [item.id,item.user_name,item.disposition_contact,item.call_type,item.disposition,item.disposition_info,item.disposition_time.slice(0, -16).replace('T', ' ')].join(','))
        ).join('\n');
        const csvBlob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob,`${formData.get('keyword')}-${formData.get('start_date')}-${formData.get('end_date')}-Report.csv`);
      }else{
        setOpenError(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);  
    setOpenError(true);
    }
  }


  function calculateTimeDifference(start, end) {
    if( start && end){
    // Parse start_time and end_time
    const startTimeParts = start.split(":");
    const endTimeParts = end.split(":");
  
    const startHour = parseInt(startTimeParts[0]);
    const startMinute = parseInt(startTimeParts[1]);
    const startSecond = parseInt(startTimeParts[2]);
  
    const endHour = parseInt(endTimeParts[0]);
    const endMinute = parseInt(endTimeParts[1]);
    const endSecond = parseInt(endTimeParts[2]);
  
    // Calculate the time difference
    const hoursDiff = endHour - startHour;
    const minutesDiff = endMinute - startMinute;
    const secondsDiff = endSecond - startSecond;
  
    // Return the formatted time difference
    return `${hoursDiff} h ${minutesDiff} m ${secondsDiff} s`;
    }
  }

  function secondsToHMS(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
  
    const formattedHours = hours.toString();
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
  
    return `${formattedHours}.${formattedMinutes}.${formattedSeconds}`;
  }

  const handleHangup = (hangup_cause) => {
    if (hangup_cause=='NO_ANSWER'){
      return 'UN-ANSWERED';
    } else if (hangup_cause=='ORIGINATOR_CANCEL'){
      return 'CALLER CANCELED';
    } else if(hangup_cause=='NORMAL_CLEARING'){
      return 'ANSWERED';
    } else if(hangup_cause=='NO_USER_RESPONSE'){
      return 'DROPPED';
    } else if(hangup_cause=='USER_BUSY'){
      return 'ENGAGED';
    } else if(hangup_cause=='SUBSCRIBER_ABSENT'){
      return 'EXTENSION UNAVAILABLE';
    } else if(hangup_cause=='All'){
      return 'ALL';
    }else{
      return hangup_cause;
    }
  }
  
  const handleBreakReportSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const accessToken = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };
    try {
      const response = await api.get('/callbreakreport/', {
        params: {
          keyword: formData.get('keyword'),
          start_date: formData.get('start_date'),
          end_date: formData.get('end_date'),
        },     
          headers,
          withCredentials: true,
      });
      if(response.data.length>0){
        setOpenSuccess(false);
        const headings = ['Name','Break','Start','Stop','Duration','Date'];
        const csvContent = [headings.join(',')].concat(response.data.map(item => [item.user_name,item.break_type,item.start_time,item.end_time,calculateTimeDifference(item.start_time, item.end_time),item.date.slice(0, -16).replace('T', ' ')].join(','))
        ).join('\n');
        const csvBlob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob,`${formData.get('keyword')}-${formData.get('start_date')}-${formData.get('end_date')}-Break_Report.csv`);
      }else{
        setOpenError(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);  
    setOpenError(true);
    }
  }


  const handleContactReportSubmit = async (event) => {
    event.preventDefault();
    const accessToken = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };
    try {
      const response = await api.get('/dialercontants/', {
        headers,
        withCredentials: true,
      });
      if(response.data.length>0){
        setOpenSuccess(false);
        const headings = ['Name','Contact','Email','Label','Category','Date'];
        const csvContent = [headings.join(',')].concat(response.data.map(item => [item.name,item.phone_number,item.email,item.location,item.category,item.date].join(','))
        ).join('\n');
        const csvBlob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob,`Contacts_Export-Report.csv`);
      }else{
        setOpenError(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);  
    setOpenError(true);
    }
  }


  const handleExtensiveReportSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const accessToken = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };
    try {
      const response = await api.get('/extensivereport/', {
        params: {
          keyword: formData.get('keyword'),
          start_date: formData.get('start_date'),
          end_date: formData.get('end_date'),
        },     
          headers,
          withCredentials: true,
      });
      const keyword = formData.get('keyword');
      if(keyword!='Extensive'){
     if(response.data.length>0){
        setOpenSuccess(false);
        let headings;
        headings = ['Unique','Source','Destination','Duration','Call Type','Time/Date','Status'];
        const csvContent = [headings.join(',')].concat(response.data.map(item => [item.xml_cdr_uuid,item.json.variables.caller_id_number &&
          item.json.variables.caller_id_number.length > 5
          ? item.json.variables.caller_id_number.slice(3)
          : item.json.variables.caller_id_number,item.json.variables.caller_destination,secondsToHMS(item.duration),item.direction,item.end_stamp.slice(0, -10).replace('T', ' '),handleHangup(item.json.variables.hangup_cause)].join(','))
        ).join('\n');
        const csvBlob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const ReportName = 'Extensive';
        saveAs(csvBlob,`${ReportName}-${formData.get('start_date')}-${formData.get('end_date')}-${handleHangup(formData.get('keyword'))}_Report.csv`);
      }else{
        setOpenError(true);
      }
      }else{

        if (response.data.length > 0) {
          setOpenSuccess(false);
        
          const flattenObject = (obj, parentKey = '') => {
            return Object.entries(obj).reduce((acc, [key, value]) => {
              const newKey = parentKey ? `${parentKey}.${key}` : key;
              return typeof value === 'object' ? { ...acc, ...flattenObject(value, newKey) } : { ...acc, [newKey]: value };
            }, {});
          };
        
          // Extract unique flattened keys from all objects in the data
          const flattenedKeysSet = new Set(
            response.data.flatMap(item => Object.keys(flattenObject(item.json.variables)))
          );
        
          // Convert the set to an array
          const flattenedKeys = Array.from(flattenedKeysSet);
        
          // Create CSV headers for each variable
          const headers = [...flattenedKeys.map(key => key.replace(/json\.variables\./g, ''))];
        
          // Create CSV content by mapping over each item in the data
          const csvContent = [
            headers.join(','),
            ...response.data.map(item => {
              const flattenedItem = flattenObject(item.json.variables);
              return flattenedKeys.map(key => (flattenedItem[key] || '').toString()).join(',');
            }),
          ].join('\n');
        
          const csvBlob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
          const ReportName = 'Extensive';
          saveAs(csvBlob, `${ReportName}-${formData.get('start_date')}-${formData.get('end_date')}-${ReportName}_Report.csv`);
        }
        
        else{
          setOpenError(true);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);  
    // setOpenError(true);
    }
  };



  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
const headers = {
  'Authorization': `Bearer ${accessToken}`,
  'Content-Type': 'application/json',
};
    const indivvalue = "";
    const params = {search : indivvalue}
    api.post(`/getusers/`,params,{
      headers,
      withCredentials: true,
    })
      .then(response => {
        const indivresultdata = response.data;
        const filteredIndResults = indivresultdata.filter(item =>
          item.user_name.toLowerCase().includes(indivvalue.toLowerCase()) || 
          item.user_id.toLowerCase().includes(indivvalue.toLowerCase()) || 
          item.user_extension.toLowerCase().include(indivvalue.toLowerCase())
        );
        setIndividualValue(filteredIndResults);
      })
      .catch(error => {
        console.error(error);
      });
    }, []);


    const fetchTicketCategoryData = () => {
      const accessToken = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      api.get('/ticketcategory/', {
        headers,
        withCredentials: true,
      })
        .then(response => {
          setTicketCatgoryData(response.data);
        })
        .catch(error => {
          console.error(error);
        });

    }

    const fetchDispositionCategoryData = () => {
      const accessToken = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      api.get('/dispositiongroup/', {
        headers,
        withCredentials: true,
      })
        .then(response => {
          setDispositionCatgoryData(response.data);
        })
        .catch(error => {
          console.error(error);
        });

    }

    const fetchBreakTypeData = () => {
      const accessToken = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      api.get('/callbreaktypegroup/', {
        headers,
        withCredentials: true,
      })
        .then(response => {
          setBreakTypeData(response.data);
        })
        .catch(error => {
          console.error(error);
        });

    }

    const fetchCallLogsData = () => {
      const accessToken = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      api.get('/calllogdatagroup/', {
        headers,
        withCredentials: true,
      })
        .then(response => {
          setCallLogsData(response.data);
        })
        .catch(error => {
          console.error(error);
        });

    }

    useEffect(() => {
      fetchTicketCategoryData();
      fetchDispositionCategoryData();
      fetchBreakTypeData();
      fetchCallLogsData();
    }, []);


  // const generatePDF = () => {
  //   const MyPDF = () => (
  //     <Document>
  //       <Page>
  //         {data.map(item => (
  //           <Text key={item.id}>{item.description}</Text>
  //         ))}
  //       </Page>
  //     </Document>
  //   );

  //   const pdfBlob = new Blob([<MyPDF />], { type: 'application/pdf' });
  //   saveAs(pdfBlob, 'data.pdf');
  // };

  // const generateCSV = () => {
  //   const csvContent = data.map(item => [item.id, item.description].join(',')).join('\n');
  //   const csvBlob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  //   saveAs(csvBlob, 'data.csv');
  // };

  return (
    <Box sx={{marginTop:'60px',paddingLeft:'10px', flexGrow: 1, p: 3 }}>
      <TokenExpiration/>
      <Expiration/>
    <Typography variant="h6" paddingBottom={2}>Reports</Typography>
    <Grid  spacing={2}  p={1} >
        <Grid item xs={12} sx={{ backgroundColor: `${colors.primary[700]} !important`, paddingBottom: '10px',marginTop:'10px',borderRadius:'5px',padding:'10px !important'}}>
<Box position="sticky"
    top="-10px">
<Tabs
  value={selectedTab}
  onChange={handleTabChange}
  textColor="secondary"
  indicatorColor="secondary"
  variant="fullWidth"
      >
        <Tab label="Calls"/>
        <Tab label="Individual"/>
        <Tab  label="Ticketing"/>
        <Tab  label="Disposition"/>
        <Tab  label="Performance"/>
        <Tab  label="Breaks"/>
        <Tab  label="Contacts"/>
        <Tab  label="Extensive"/>
      </Tabs>

      </Box>

      {selectedTab === 0 && <span>
        
        <form onSubmit={handleCallReportSubmit}>
        <Grid container spacing={2} p={2}>
        <Grid item xs={12}>
        <TextField
        fullWidth
        select
        label="Select Report"
        name="keyword"
        sx={{marginTop:"10px"}}
        onChange={handleInputChange}
        required
      >
        <MenuItem disabled>Select Report</MenuItem>
        <MenuItem value="all">All</MenuItem>
        <MenuItem value="Received">Received</MenuItem>
        <MenuItem value="Incoming">Incoming</MenuItem>
        <MenuItem value="Outgoing">Outgoing</MenuItem>
        {CallLogsData.map(row => (
        <MenuItem value={row.call_duration}>{row.call_duration}</MenuItem>
        ))}
      </TextField>
        </Grid>
        <Grid item xs={6}>
            <label sx={{textAlign:'left'}}>Start Date</label>
        <TextField
              name="start_date"
              type="date"
              onChange={handleInputChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required
            />
            <TextField
            name="report"
            value="Call"
            sx={{display:'none!important'}}
            />
        </Grid>
        <Grid item xs={6}>
            <label>End Date</label>
        <TextField
              name="end_date"
              type="date"
              onChange={handleInputChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required
            />
        </Grid>


        <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          type="submit"
          sx={{padding:'10px',marginTop:'12px',background: colors.greenAccent[700] }}

        >
         Generate
        </Button>
        </Grid>
        </Grid>
        </form>
            </span>}
            {selectedTab === 1 && <span>
                
              <form onSubmit={handleCallIndividualReportSubmit}>
        <Grid container spacing={2} p={2}>

        <Grid item xs={12}>
        <TextField
        fullWidth
        select
        label="Select Individual"
        name="user_id"
        sx={{marginTop:"10px"}}
        onChange={handleInputChange}
        required
      >
<MenuItem disabled>Select Individual</MenuItem>
{IndividualValue.map(item => (
  <MenuItem value={item.user_id}>{item.user_name} ({item.user_extension})</MenuItem>

))}
      </TextField>
      <TextField
            name="report"
            value="Individual Calls"
            sx={{display:'none!important'}}
            />
        </Grid>


        <Grid item xs={12}>
        <TextField
        fullWidth
        select
        label="Select Report"
        name="keyword"
        sx={{marginTop:"10px"}}
        onChange={handleInputChange}
        required
      >
        <MenuItem disabled>Select Report</MenuItem>
        <MenuItem value="all">All</MenuItem>
        <MenuItem value="Received">Received</MenuItem>
        <MenuItem value="Incoming">Incoming</MenuItem>
        <MenuItem value="Outgoing">Outgoing</MenuItem>
        {CallLogsData.map(row => (
        <MenuItem value={row.call_duration}>{row.call_duration}</MenuItem>
        ))}
      </TextField>
        </Grid>
        <Grid item xs={6}>
            <label sx={{textAlign:'left'}}>Start Date</label>
        <TextField
              name="start_date"
              type="date"
              onChange={handleInputChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required
            />
        </Grid>
        <Grid item xs={6}>
            <label>End Date</label>
        <TextField
              name="end_date"
              type="date"
              onChange={handleInputChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required
            />
        </Grid>


        <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          type="submit"
          sx={{padding:'10px',marginTop:'12px',background: colors.greenAccent[700] }}

        >
         Generate
        </Button>
        </Grid>
        </Grid>
        </form>
            </span>}


            {selectedTab === 2 && <span> 
              <form onSubmit={handleTicketReportSubmit}>
        <Grid container spacing={2} p={2}>
        <Grid item xs={12}>

      <TextField
        fullWidth
        select
        label="Ticket Category"
        name="keyword"
        onChange={handleInputChange}
        required="true"
        sx={{marginTop:"5px"}}
      >
<MenuItem disabled>Select Ticket Category</MenuItem>
<MenuItem value="all">All</MenuItem>
{TicketCatgoryData.map(row => (
  <MenuItem value={row.category}>{row.category}</MenuItem>

))}
      </TextField>
      <TextField
            name="report"
            value="Ticket"
            sx={{display:'none!important'}}
            />
        </Grid>
        <Grid item xs={6}>
            <label sx={{textAlign:'left'}}>Start Date</label>
        <TextField
              name="start_date"
              type="date"
              onChange={handleInputChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required
            />
        </Grid>
        <Grid item xs={6}>
            <label>End Date</label>
        <TextField
              name="end_date"
              type="date"
              onChange={handleInputChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required
            />
        </Grid>


        <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          type="submit"
          sx={{padding:'10px',marginTop:'12px',background: colors.greenAccent[700] }}

        >
         Generate
        </Button>
        </Grid>
        </Grid>
        </form>
              </span>}



              {selectedTab === 3 && <span> 
              <form onSubmit={handleDispositionReportSubmit}>
        <Grid container spacing={2} p={2}>
        <Grid item xs={12}>

        <TextField
        fullWidth
        select
        label="Disposition"
        name="keyword"
        onChange={handleInputChange}
        required="true"
        sx={{marginTop:"5px"}}
      >
<MenuItem disabled>Select Ticket Disposition</MenuItem>
<MenuItem value="all">All</MenuItem>
{DispositionCatgoryData.map(row => (
  <MenuItem value={row.disposition}>{row.disposition}</MenuItem>

))}
      </TextField>
      <TextField
            name="report"
            value="Ticket"
            sx={{display:'none!important'}}
            />

        </Grid>
        <Grid item xs={6}>
            <label sx={{textAlign:'left'}}>Start Date</label>
        <TextField
              name="start_date"
              type="date"
              onChange={handleInputChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required
            />
        </Grid>
        <Grid item xs={6}>
            <label>End Date</label>
        <TextField
              name="end_date"
              type="date"
              onChange={handleInputChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required
            />
        </Grid>


        <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          type="submit"
          sx={{padding:'10px',marginTop:'12px',background: colors.greenAccent[700] }}

        >
         Generate
        </Button>
        </Grid>
        </Grid>
        </form>
              </span>}
              {selectedTab === 4 && <span> 
                <form onSubmit={handlePerformanceReportSubmit}>
        <Grid container spacing={2} p={2}>

        <Grid item xs={12}>
        <TextField
        fullWidth
        select
        label="Select Report"
        name="keyword"
        sx={{marginTop:"10px"}}
        onChange={handleInputChange}
        required
      >
        <MenuItem disabled>Select Report</MenuItem>
        <MenuItem value="all">All</MenuItem>
        <MenuItem value="Received">Received</MenuItem>
        <MenuItem value="Incoming">Incoming</MenuItem>
        <MenuItem value="Outgoing">Outgoing</MenuItem>
        {CallLogsData.map(row => (
        <MenuItem value={row.call_duration}>{row.call_duration}</MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        name="report"
        value="performance"
        sx={{display:'none!important'}}
      />
        </Grid>
        <Grid item xs={6}>
            <label sx={{textAlign:'left'}}>Start Date</label>
        <TextField
              name="start_date"
              type="date"
              onChange={handleInputChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required
            />
        </Grid>
        <Grid item xs={6}>
            <label>End Date</label>
        <TextField
              name="end_date"
              type="date"
              onChange={handleInputChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required
            />
        </Grid>


        <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          type="submit"
          sx={{padding:'10px',marginTop:'12px',background: colors.greenAccent[700] }}

        >
         Generate
        </Button>
        </Grid>
        </Grid>
        </form>
              </span>}




              {selectedTab === 5 && <span> 
              <form onSubmit={handleBreakReportSubmit}>
        <Grid container spacing={2} p={2}>
        <Grid item xs={12}>

        <TextField
        fullWidth
        select
        label="Breaks"
        name="keyword"
        onChange={handleInputChange}
        required="true"
        sx={{marginTop:"5px"}}
      >
<MenuItem disabled>Select Break Types</MenuItem>
<MenuItem value="all">All</MenuItem>
{BreakTypeData.map(row => (
  <MenuItem value={row.break_type}>{row.break_type}</MenuItem>

))}
      </TextField>

      <TextField
            name="report"
            value="Breaks"
            sx={{display:'none!important'}}
            />
        </Grid>
        <Grid item xs={6}>
            <label sx={{textAlign:'left'}}>Start Date</label>
        <TextField
              name="start_date"
              type="date"
              onChange={handleInputChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required
            />
        </Grid>
        <Grid item xs={6}>
            <label>End Date</label>
        <TextField
              name="end_date"
              type="date"
              onChange={handleInputChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required
            />
        </Grid>


        <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          type="submit"
          sx={{padding:'10px',marginTop:'12px',background: colors.greenAccent[700] }}

        >
         Generate
        </Button>
        </Grid>
        </Grid>
        </form>
              </span>}


              {selectedTab === 6 && <span> 
              <form onSubmit={handleContactReportSubmit}>
        <Grid container spacing={2} p={2}>

        <Grid item xs={12}>
           Export Contacts
        </Grid>

        <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          type="submit"
          sx={{padding:'10px',marginTop:'12px',background: colors.greenAccent[700] }}

        >
         Generate
        </Button>
        </Grid>
        </Grid>
        </form>
              </span>}


              {selectedTab === 7 && <span> 
              <form onSubmit={handleExtensiveReportSubmit}>
        <Grid container spacing={2} p={2}>

        <Grid item xs={12}>
           Extensive Call Reports
        </Grid>

        <Grid item xs={12}>
        <TextField
        fullWidth
        select
        label="Select Report"
        name="keyword"
        sx={{marginTop:"10px"}}
        onChange={handleInputChange}
        required
      >
        <MenuItem disabled>Select Report</MenuItem>
        <MenuItem value="All">All</MenuItem>
        {sessionStorage.getItem("user_level") === "admin" && (
        <MenuItem value="Extensive">Extensive</MenuItem> )}
        <MenuItem value="NO_ANSWER">Un-Answered</MenuItem>
        <MenuItem value="NORMAL_CLEARING">Answered</MenuItem>
        <MenuItem value="USER_BUSY">Engaged</MenuItem>
        <MenuItem value="SUBSCRIBER_ABSENT">Unavailable</MenuItem>
      </TextField>
      </Grid>

        <Grid item xs={6}>
            <label sx={{textAlign:'left'}}>Start Date</label>
        <TextField
              name="start_date"
              type="date"
              onChange={handleInputChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required
            />
        </Grid>
        <Grid item xs={6}>
            <label>End Date</label>
        <TextField
              name="end_date"
              type="date"
              onChange={handleInputChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required
            />
        </Grid>

        <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          type="submit"
          sx={{padding:'10px',marginTop:'12px',background: colors.greenAccent[700] }}

        >
         Generate
        </Button>
        </Grid>
        </Grid>
        </form>
              </span>}

        </Grid>
        </Grid>

        <Stack spacing={2} sx={{ width: '100%'}}>
  <Snackbar open={openError} autoHideDuration={4000} onClose={handleErrorClose}  anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
  <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
Error Occured / No Data!!!
  </Alert>
  </Snackbar>

  <Snackbar open={openSuccess} autoHideDuration={4000} onClose={handleSuccessClose}  anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
  <Alert onClose={handleSuccessClose} severity="success" sx={{ width: '100%' }}>
  Success 
  </Alert>
  </Snackbar>
  </Stack>

  </Box>
  );
}

export default Reports;