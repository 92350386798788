import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Button,
  MenuItem,
  Card, 
  CardContent,
  Modal,
  Autocomplete,
  Tabs, 
  Tab,
  Divider,
  Grid,
  Box,
  Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { ColorModeContext, tokens } from "../theme";
import axios from 'axios';
import { global_link } from '../Components/Globallink';
import TokenExpiration from '../Auth/TokenExpiration';
import Expiration from '../Components/Expiration';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTimer } from '../Components/Timer';
const api = axios.create({baseURL: global_link});


const Monitor = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [activeCalls, setActiveCalls] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [bargemodal, setBargeModal] = useState(false);
    const [spymodal, setSpyModal] = useState(false);
    const [whispermodal, setWhisperModal] = useState(false);
    const {timer, startTimer, resetTimer} = useTimer();
    const [liveExtensions, setLiveExtensions] = useState([]);
    const [liveCalls, setLiveCalls] = useState([]);
    const [error, setError] = useState('');

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 450,
      borderRadius: '10px',
      boxShadow: 24,
      p: 3,
    };



    const handleTabChange = (event, newValue) => {
      setSelectedTab(newValue);
      
      if (newValue === 0) {
        active_extensions();
      } else if (newValue === 1) {
        active_calls();
      }
    };


    const active_calls = async () => {
      const accessToken = localStorage.getItem('access_token');
      const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      };
      try {
        const response = await api.get('/active-calls/', {
          headers,
          withCredentials: true,
        });
        setLiveCalls(response.data.activeCalls.rows || []);
    } catch (err) {
        setError('Failed to fetch live extensions.');
        console.error(err);
    }
    
    };

    const active_extensions = async () => {
      const accessToken = localStorage.getItem('access_token');
      const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      };
      try {
        const response = await api.get('/active-extensions/', {
          headers,
          withCredentials: true,
        });
        setLiveExtensions(response.data.activeExtensions || []);
    } catch (err) {
        setError('Failed to fetch live extensions.');
        console.error(err);
    }
    
    };


    
  
          useEffect(() => {
            active_calls();
            active_extensions();
          }, []);

  return (
    <Box sx={{marginTop:'60px',paddingLeft:'10px', flexGrow: 1, p: 3 }}>
        <TokenExpiration/>
        <Expiration/>
    <Typography variant="h6" paddingBottom={2}>Monitor</Typography>

    <Tabs
      value={selectedTab}
      onChange={handleTabChange}
      textColor="secondary"
      indicatorColor="secondary"
      variant="fullWidth"
      sx={{ backgroundColor: `${colors.primary[700]} !important` }}
    >
      <Tab label="Active Extensions" />
      <Tab label="Active Calls" />
    </Tabs>
      <Divider/>
    {selectedTab === 0 && (
      <span>
<Table sx={{marginTop:"20px" }}>
        <TableHead sx={{backgroundColor:`${colors.primary[600]}`}}>
          <TableRow>
            {/* <TableCell>Name</TableCell> */}
            <TableCell>Extension</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{backgroundColor:`${colors.primary[700]}`}}>
        {liveExtensions.map(row => (
            <TableRow key={row.id}>
              {/* <TableCell>{row.user}</TableCell> */}
              <TableCell>{row.extension}</TableCell>
              <TableCell>{'Registered'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </span>
      )}

     
    {selectedTab === 1 && (
    
    <span>
      <Table sx={{marginTop:"20px"}}>
        <TableHead sx={{backgroundColor:`${colors.primary[600]}`}}>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Caller / Extension</TableCell>
            <TableCell>Number</TableCell>
            <TableCell>Direction</TableCell>
            <TableCell>Created</TableCell>
            {/* <TableCell>Action</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody sx={{backgroundColor:`${colors.primary[700]}`}}>
          {liveCalls.map(row => (
            <TableRow key={row.id}>
            <TableCell>{row.cid_name}</TableCell>
            <TableCell>{row.cid_num}</TableCell>
            <TableCell>{row.dest}</TableCell>
            <TableCell>{row.direction}</TableCell>
            <TableCell>{row.created}</TableCell>
        {/* <TableCell><Button variant='contained' sx={{backgroundColor: colors.blueAccent[600], fontWeight:"bold"}}>SPY</Button> | <Button variant='contained' sx={{backgroundColor: colors.blueAccent[600], fontWeight:"bold"}}>WHISPER</Button> | <Button variant='contained' sx={{backgroundColor: colors.blueAccent[600], fontWeight:"bold"}}>BARGE</Button></TableCell> */}
      </TableRow>

          ))}
        </TableBody>
      </Table>

      
<Modal
open={spymodal}
onClose={() =>setSpyModal(false)}
aria-labelledby="modal-title"
aria-describedby="modal-description"
>
<Box backgroundColor={colors.primary[700]} sx={style}>
<div className="modal-content">
<Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
<h3 id="modal-title">SPY</h3>
<Box display="flex" justifyContent="right">
<CancelIcon onClick={() => setSpyModal(false)} sx={{cursor:"pointer"}}/>
</Box>
</Box>
<Box sx={{display: 'flex', justifyContent: 'center', width: '100%' }}>
Spying On 
<Divider/>
{/* {timer} */}
</Box>
</div>
</Box>
</Modal>



<Modal
open={whispermodal}
onClose={() =>setWhisperModal(false)}
aria-labelledby="modal-title"
aria-describedby="modal-description"
>
<Box backgroundColor={colors.primary[700]} sx={style}>
<div className="modal-content">
<Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
<h3 id="modal-title">Whisper</h3>
<Box display="flex" justifyContent="right">
<CancelIcon onClick={() => setWhisperModal(false)} sx={{cursor:"pointer"}}/>
</Box>
</Box>

<Box sx={{display: 'flex', justifyContent: 'center', width: '100%' }}>
Whispering On 
<Divider/>
{/* {timer} */}
</Box>

</div>
</Box>
</Modal>


<Modal
open={bargemodal}
onClose={() =>setBargeModal(false)}
aria-labelledby="modal-title"
aria-describedby="modal-description"
>
<Box backgroundColor={colors.primary[700]} sx={style}>
<div className="modal-content">
<Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
<h3 id="modal-title">Barge</h3>
<Box display="flex" justifyContent="right">
<CancelIcon onClick={() => setBargeModal(false)} sx={{cursor:"pointer"}}/>
</Box>
</Box>

<Box sx={{display: 'flex', justifyContent: 'center', width: '100%' }}>
Barging On 
<Divider/>
{/* {timer} */}
</Box>


</div>
</Box>
</Modal>

                    </span>


        )}

    </Box>
  );
};

export default Monitor;

